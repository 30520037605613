<template>
    <div class="profileModal top-20 fixed invisible shadow-xl w-4/6 justify-center items-center" :class="{visibleModal: isVisible}">
        <div class="flex flex-col modal-content p-5 bg-white z-20 relative rounded-xl border-2 border-olive">
            <div class="flex items-center px-5 justify-between">
                <div>
                    <h1 class="text-xl font-bold select-none">Edit profile</h1>
                </div>
                <div class="cursor-pointer" @click="closeModal">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-9 w-9" fill="none" viewBox="0 0 24 24" stroke="#acbf69">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>
                </div>
            </div>
            <div class="pt-2 pb-3">
                <hr />
            </div>
            <div class="flex">
                <div class="flex flex-col p-5 flex-grow">
                    <div class="flex flex-col flex-grow">
                        <div class="text-olive font-bold text-2xl">{{ this.profile.full_name }}</div>
                        <div class="text-xl">{{ this.profile.current_role }}</div>
                        <div class="text-xl">Sydney</div>
                    </div>

                    <div class="flex mt-3">
                        <div class="flex flex-basis-30 text-olivelight">Company</div>
                        <div class="flex flex-basis-70 text-xl">{{ this.profile.current_company }}</div>
                    </div>
                    <div class="flex mt-2">
                        <div class="flex flex-basis-30 text-olivelight">Company tenure</div>
                        <div class="flex flex-basis-70 text-xl">{{ $filters.screenedDate(this.profile.experience_company) }}</div>
                    </div>
                    <div class="flex mt-2">
                        <div class="flex flex-basis-30 text-olivelight">Total experience</div>
                        <div class="flex flex-basis-70 text-xl">{{ $filters.screenedDate(this.profile.total_experience) }}</div>
                    </div>
                    <div class="flex mt-2">
                        <div class="flex flex-basis-30 text-olivelight">Companies</div>
                        <div class="flex flex-basis-70 text-xl">{{ this.profile.total_companies }} <span v-if="this.profile.companies != null">&nbsp;({{ JSON.parse(this.profile.companies).reverse().join(', ') }})</span></div>
                    </div>
                    <div class="flex mt-2">
                        <div class="flex flex-basis-30 text-olivelight">Loyalty</div>
                        <div class="flex flex-basis-70 text-xl">{{ $filters.screenedDate(this.profile.loyalty) }}</div>
                    </div>

                    <div class="flex mt-2">
                        <div class="flex flex-basis-30 text-olivelight">Salary</div>
                        <div class="flex flex-basis-70 text-xl content-center items-center">
                            <div v-if="this.edit && this.edit.current != 'salary'" class="">{{ $filters.screenedSalary(this.editableFields.salary != null ?  this.editableFields.salary : this.profile.salary) }}</div>
                            <div v-if="this.edit && this.edit.isEdit && this.edit.current == 'salary'" class="flex items-center">
                                <div class="flex items-center">
                                    <span class="mr-2">$</span>
                                    <input ref="salaryField" class="block rounded-md border-black focus:border-gray-500 focus:bg-white focus:ring-0" type="number" :value="this.editableFields.salary != null?  this.editableFields.salary : this.profile.salary" />
                                </div>

                                <div class="ml-3 text-olive cursor-pointer" @click="saveEdit('salary', this.$refs.salaryField.value)">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </div>
                            </div>
                            
                            <div v-if="this.edit && !this.edit.isEdit" @click="editField('salary')" class="ml-3 text-olive cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                </svg>
                            </div>
                        </div>
                    </div>

                    <div class="flex mt-2">
                        <div class="flex flex-basis-30 text-olivelight">Availability</div>
                        <div class="flex flex-basis-70 text-xl content-center items-center">
                            <div v-if="this.edit && this.edit.current != 'availability'" class="">{{ $filters.screenedAvailability(this.editableFields.availability ?  this.editableFields.availability : this.profile.availability) }}</div>
                            <div v-if="this.edit && this.edit.isEdit && this.edit.current == 'availability'" class="flex items-center">
                                <div>
                                    <select ref="availabilityField" class="block rounded-md border-black focus:border-gray-500 focus:bg-white focus:ring-0">
                                        <option value="0.2" :selected="this.editableFields && this.editableFields.availability == 0.2">1 week</option>
                                        <option value="0.5" :selected="this.editableFields && this.editableFields.availability == 0.5">2 weeks</option>
                                        <option value="1" :selected="this.editableFields && this.editableFields.availability == 1">1 month</option>
                                    </select>
                                </div>

                                <div class="ml-3 text-olive cursor-pointer" @click="saveEdit('availability', this.$refs.availabilityField.value)">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                </div>
                            </div>
                            <div v-if="this.edit && !this.edit.isEdit" @click="editField('availability')" class="ml-3 text-olive cursor-pointer">
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                </svg>
                            </div>
                        </div>
                    </div>

                    <div v-if="!this.profile.pools || this.profile.pools.length == 0" class="flex mt-2">
                        <div class="flex flex-basis-30 text-olivelight">Talent pools</div>
                        <div class="flex flex-basis-70 text-xl content-center items-center">
                            <div class="">None</div>
                        </div>
                    </div>
                </div>
                <div class="flex items-center flex-col p-5">
                    <div class="rounded-full h-24 w-24 flex items-center justify-center mb-3 text-olive font-bold text-3xl border-2 border-olive">{{ $filters.initials(this.profile.full_name) }}</div>
                    <router-link to="" class="mt-4 flex flex-col items-center my-auto text-olive">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-10 w-10" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                        </svg>
                        <span class="text-center text-black">Download<br />resume</span>
                    </router-link>
                    <div class="text-right text-xs mt-3">
                        Parsed on {{ this.profile.parse_date }}
                    </div>
                </div>
            </div>

            <div v-if="this.profile.pools && this.profile.pools.length > 0" class="flex flex-col px-5 mt-2">
                <div class="flex text-olivelight">Talent pools</div>
                <div class="flex space-x-4 text-xl flex-wrap">
                    <div class="px-3 py-1 cursor-pointer hover:text-white rounded-md bg-olive" v-for="pool in this.profile.pools" :key="pool.talent_pool_id">
                        <router-link :to="`/talent-pools/${pool.talent_pool_hash}`" >{{ pool.name }}</router-link>
                    </div>
                </div>
            </div>

            <div class="flex px-5 flex-col mt-2">
                <div class="flex text-olivelight">Notes</div>
                <div class="flex mt-2 text-xl">
                    <textarea ref="notesField" class="block w-full rounded-md bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0" rows="5" :value="this.profile.notes"></textarea>
                </div>
            </div>
            <div class="flex mt-5 px-5 justify-end">
                <!-- <button :class="{disabledSave: !this.hasEditedSomething()}" class="bg-olive px-5 py-2 rounded-md text-white font-bold" @click="saveChanges">Save changes</button> -->
                <button class="bg-olive px-5 py-2 rounded-md text-white font-bold" @click="saveChanges">{{ saveButtonText }}</button>
            </div>
        </div>
        <div class="overlay bg-black z-10 w-full h-full fixed top-0 left-0 opacity-40" :value="this.profile.notes"></div>
    </div>
</template>

<script>
// let apiEndpoint = 'http://localhost:9999'
let apiEndpoint = 'http://screened.remotehub.ro'

export default {
    components: {},
    data() {
        return {
            isVisible: false,
            profile: {},
            profilePools: [],
            edit: {
                isEdit: false,
                current: null
            },
            editableFields: {
                salary: null,
                availability: null,
                notes: null
            },
            saveButtonText: 'Save changes',
        }
    },
    methods: {
        openModal(ph) {
            this.isVisible = true
            this.profile = ph

            if (this.profile.pools == null) {
                this.getProfilePools()
            } else {
                console.log(this.profile.pools)
            }
        },
        closeModal() {
            this.isVisible = false
            this.edit = {
                isEdit: false,
                current: null
            }
            this.editableFields = {
                salary: null,
                availability: null,
                notes: null
            }
        },
        editField(field) {
            // If already editing
            this.edit.isEdit = true
            this.edit.current = field
        },
        saveEdit(field, value) {
            // Make sure we are currently editing that field
            if (!this.edit.isEdit || this.edit.current != field) {
                return
            }

            // For salary, remove decimals.
            // TODO: Remove +/- signs
            if (field == 'salary') {
                value = Math.trunc(value)
            }

            // We want to update the, reset edit, save editable field data
            this.edit.isEdit = false
            this.edit.current = null

            // Only set it the value in the field has actually changed
            if (this.profile[field] != value) {
                this.editableFields[field] = value
            } else {
                this.editableFields[field] = null
            }
        },
        saveChanges() {
            // Check if notes has changed and update editable fields
            if (this.profile.notes != this.$refs.notesField.value) {
                this.editableFields.notes = this.$refs.notesField.value
            }
            
            // Only process if something has changed
            if (this.hasEditedSomething()) {
                this.postEditableFields()
            }
        },
        hasEditedSomething() {
            if (this.editableFields.salary != null || this.editableFields.availability != null || this.editableFields.notes != null) {
                return true
            }
            return false
        },
        async postEditableFields() {
            this.saveButtonText = 'Saving...'
            try {
                fetch(apiEndpoint + "/api/profile.php?setFields&ph=" + this.profile.profile_hash, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(this.editableFields),
                    })
                .then(response => response.json())
                .then(data => {
                    this.saveButtonText = 'Save changes'
                    if (data.status == 'SUCCESS') {
                        this.saveEditableFields();
                        this.resetEditableFields();
                    }
                })
            } catch (error) {
                console.log(error);
                this.saveButtonText = 'Save changes'
            }
            
        },
        resetEditableFields() {
            this.editableFields.salary = null
            this.editableFields.availability = null
            this.editableFields.notes = null
        },
        saveEditableFields() {
            if (this.editableFields.salary != null) {
                if (this.editableFields.salary == 0) {
                    this.profile.salary = null;
                } else {
                    this.profile.salary = this.editableFields.salary;
                }
            }

            if (this.editableFields.availability != null) {
                this.profile.availability = this.editableFields.availability
            }

            if (this.editableFields.notes != null) {
                this.profile.notes = this.editableFields.notes
            }
        },
        async getProfilePools() {
            try {
                let response = await fetch(apiEndpoint + "/api/talentpool.php?getProfilePools&ph=" + this.profile.profile_hash);
                this.profile.pools = await response.json();
            } catch (error) {
                console.log(error);
            }
        }
    }
}
</script>

<style>
/* .hiddenModal {
    visibility: hidden;
}*/

.disabledSave {
    cursor: not-allowed !important;
    pointer-events: all !important;
    background-color: gray !important;
}

.visibleModal {
    visibility: visible !important;
}
/* HIDE ARROWS FROM NUMBER INPUT */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>