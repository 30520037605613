<template>
    <div class="flex w-100 bg-white rounded-xl mb-5 hover:shadow-2xl">
        <ProfileModal ref="profileModal"></ProfileModal>
        <div class="flex flex-col p-5 flex-grow cursor-pointer" @click="openProfile()">
            <div class="flex flex-col flex-grow">
                <div class="text-olive font-bold text-2xl">{{ this.profile.full_name }}</div>
                <div class="text-xl">{{ this.profile.current_role }}</div>
                <div class="text-xl">Sydney</div>
            </div>

            <div class="flex mt-3">
                <div class="flex flex-basis-30 text-olivelight">Company</div>
                <div class="flex flex-basis-70 text-xl">{{ this.profile.current_company }}</div>
            </div>
            <div class="flex mt-2">
                <div class="flex flex-basis-30 text-olivelight">Company tenure</div>
                <div class="flex flex-basis-70 text-xl">{{ $filters.screenedDate(this.profile.experience_company) }}</div>
            </div>
            <div class="flex mt-2">
                <div class="flex flex-basis-30 text-olivelight">Total experience</div>
                <div class="flex flex-basis-70 text-xl">{{ $filters.screenedDate(this.profile.total_experience) }}</div>
            </div>
            <div class="flex mt-2">
                <div class="flex flex-basis-30 text-olivelight">Companies</div>
                <div class="flex flex-basis-70 text-xl">{{ this.profile.total_companies }} <span v-if="this.profile.companies != null">&nbsp;({{ JSON.parse(this.profile.companies).reverse().join(', ') }})</span></div>
            </div>
            <div class="flex mt-2">
                <div class="flex flex-basis-30 text-olivelight">Loyalty</div>
                <div class="flex flex-basis-70 text-xl">{{ $filters.screenedDate(this.profile.loyalty) }}</div>
            </div>
        </div>
        <div class="flex items-center flex-col p-5">
            <div class="rounded-full h-24 w-24 flex items-center justify-center mb-3 text-olive font-bold text-3xl border-2 border-olive">{{ $filters.initials(this.profile.full_name) }}</div>
            <div class="mt-3 flex flex-col my-auto items-center">
                <div v-if="this.profile.availability != null" class="flex flex-col items-center justify-items-center text-olive">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z" clip-rule="evenodd" />
                    </svg>
                    
                    <span class="cursor-pointer text-black">{{ $filters.screenedAvailability(this.profile.availability) }}</span>
                </div>
                <div div v-if="this.profile.salary != null" class="flex flex-col items-center justify-items-center mt-2 text-olive">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                        <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z" />
                        <path fill-rule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z" clip-rule="evenodd" />
                    </svg>

                    <span class="cursor-pointer text-black">{{ $filters.screenedSalary(this.profile.salary) }}</span>
                </div>
            </div>
            
            <div class="text-right text-xs mt-3">
                Parsed on {{ this.profile.parse_date }}
            </div>
        </div>
    </div>

    
</template>

<script>
import ProfileModal from '@/components/ProfileModal'

export default {
    name: 'BigCard',
    components: {
        ProfileModal
    },
    props: ['profile'],
    methods: {
        openProfile() {
            this.$refs.profileModal.openModal(this.profile);
        }
    }
}
</script>