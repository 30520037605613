<template>
  <div class="pt-7">
    <div v-if="!this.isEditing" class="flex bg-white rounded-xl p-5 w-100 items-center justify-between" >
      <div class="flex flex-col">
        <h1 class="text-2xl text-olive font-bold">{{ talentpool.name }}</h1>
        <div class="text-xl">{{ talentpool.location }}</div>
        <div class="pt-2">{{ talentpool.description }}</div>
      </div>

      <div class="flex flex-col justify-items-center items-center cursor-pointer" @click="switchEdit">
          <svg xmlns="http://www.w3.org/2000/svg" class="h-15 w-15" fill="none" viewBox="0 0 24 24" stroke="olive">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
          </svg>
          <span class="ml-1 mr-1 font-bold text-olive select-none">Edit</span>
      </div>
    </div>

    <div v-if="this.isEditing" class="flex bg-white rounded-xl p-5 w-100 items-center justify-between">
      <div class="flex flex-col">
        <input type="email" class="form-input">
        <h1 class="text-2xl text-olive font-bold">{{ talentpool.name }}</h1>
        <div class="text-xl">{{ talentpool.location }}</div>
        <div class="pt-2">{{ talentpool.description }}</div>
      </div>

      <div class="flex flex-col justify-items-center items-center cursor-pointer" @click="switchEdit">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-15 w-15" fill="none" viewBox="0 0 24 24" stroke="olive">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
        </svg>
        <span class="ml-1 mr-1 font-bold text-olive select-none">Save</span>
      </div>
    </div>

    <hr class="my-7" />

    <!-- <div class="mt-7">
      <h1 class="font-bold text-3xl text-olive mb-3">Profiles ({{ poolMembers.length }})</h1>
      <div class="flex grid xl:grid-cols-3 md:grid-cols-2 pt-7">
        <SmallCard v-for="profile in poolMembers" :profile="profile" :key="profile.hash" />
      </div>
    </div> -->

    <div>
      <h1 class="font-bold text-3xl text-olive mb-3">Profiles ({{ poolMembers.length }})</h1>

      <div class="flex">
        <div class="flex flex-basis-15 flex-col p-5 mr-5 bg-white rounded-xl">
          <h1 class="text-xl font-bold">Sort</h1>

          <hr />

          <div class="flex justify-between items-center cursor-pointer select-none hover:text-olive" @click="sortProfiles('total_experience')">
            <h1 class="flex-1 font-bold my-2">Experience</h1>
            <div class="">
              <svg v-if="this.sort && this.sort.name == 'total_experience' && this.sort.order == 'desc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
              </svg>
              <svg v-if="this.sort && this.sort.name == 'total_experience' && this.sort.order == 'asc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
              </svg>
            </div>
          </div>
        
          <div class="flex justify-between items-center cursor-pointer select-none hover:text-olive" @click="sortProfiles('experience_company')">
            <h1 class="font-bold my-2">Company tenure</h1>
          <div>
            <svg v-if="this.sort && this.sort.name == 'experience_company' && this.sort.order == 'desc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
            </svg>
            <svg v-if="this.sort && this.sort.name == 'experience_company' && this.sort.order == 'asc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
             <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
            </svg>
          </div>
        </div>
        <div class="flex justify-between items-center cursor-pointer select-none hover:text-olive" @click="sortProfiles('loyalty')">
          <h1 class="font-bold my-2">Loyalty</h1>
          <div>
            <svg v-if="this.sort && this.sort.name == 'loyalty' && this.sort.order == 'desc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
            </svg>
            <svg v-if="this.sort && this.sort.name == 'loyalty' && this.sort.order == 'asc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
             <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
            </svg>
          </div>
        </div>
        <div class="flex justify-between items-center cursor-pointer select-none hover:text-olive" @click="sortProfiles('total_companies')">
          <h1 class="font-bold my-2">Companies</h1>
          <div>
            <svg v-if="this.sort && this.sort.name == 'total_companies' && this.sort.order == 'desc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
            </svg>
            <svg v-if="this.sort && this.sort.name == 'total_companies' && this.sort.order == 'asc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
             <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
            </svg>
          </div>
        </div>
        <div class="flex justify-between items-center cursor-pointer select-none hover:text-olive" @click="sortProfiles('salary')">
          <h1 class="font-bold my-2">Salary</h1>
          <div>
            <svg v-if="this.sort && this.sort.name == 'salary' && this.sort.order == 'desc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
            </svg>
            <svg v-if="this.sort && this.sort.name == 'salary' && this.sort.order == 'asc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
             <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
            </svg>
          </div>
        </div>
        <div class="flex justify-between items-center cursor-pointer select-none hover:text-olive" @click="sortProfiles('availability')">
          <h1 class="font-bold my-2">Availability</h1>
          <div>
            <svg v-if="this.sort && this.sort.name == 'availability' && this.sort.order == 'desc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
            </svg>
            <svg v-if="this.sort && this.sort.name == 'availability' && this.sort.order == 'asc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
             <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
            </svg>
          </div>
        </div>

        <div class="text-center mt-3 select-none">
          <span class="text-olive cursor-pointer" @click="sortProfiles('clear')">Clear filters</span>
        </div>
      </div>
      <div class="flex flex-grow flex-col">
        <BigCard v-for="profile in poolMembers" :profile="profile" :key="profile.hash" />
      </div>
    </div>
    </div>
  </div>
</template>

<script>
// let apiEndpoint = 'http://localhost:9999'
let apiEndpoint = 'http://screened.remotehub.ro'

import BigCard from '@/components/BigCard'
import SmallCard from '@/components/SmallCard'

export default {
  name: 'TalentPool',
  components: {
    BigCard,
    SmallCard
  },
  data() {
    return {
      talentpool: {
        name: '',
        location: '',
        description: ''

      },
      poolMembers: [],
      isEditing: false,
    };
  },
  methods: {
    async getIndividualPool() {
      try {
        let response = await fetch(apiEndpoint + "/api/talentpool.php?getIndividual&tph=" + this.$route.params.poolHash);
        this.talentpool = await response.json();
        this.$emit('setChildBreadcrumb', this.talentpool.name);
      } catch (error) {
        console.log(error);
      }
    },
    async getPoolMembers() {
      let response = await fetch(apiEndpoint + "/api/talentpool.php?getMembers&tph=" + this.$route.params.poolHash);
        this.poolMembers = await response.json();
    },
    sortProfiles (field) {
      if (field == 'clear') {
        this.poolMembers = _.orderBy(this.poolMembers, 'parse_timestamp', 'DESC');
        this.sort = null;
        return
      }

      // If current sort is null, we sort by this field in a descending order
      if (this.sort == null) {
        this.poolMembers = _.orderBy(this.poolMembers, field, 'desc');
  
        // Set the new sort settings
        this.sort = {
          name: field,
          order: 'desc'
        }
      } else {
        // If the current sorted field is not the same sort by the new field in a descending order
        if (this.sort.name != field) {
          this.poolMembers = _.orderBy(this.poolMembers, field, 'desc');
          // Set the new sort settings
          this.sort = {
            name: field,
            order: 'desc'
          }
        } else {
          // Otherwise switch the type and order by the new settings
          let newOrder = this.sort.order == 'asc' ? 'desc' : 'asc'

          this.poolMembers = _.orderBy(this.poolMembers, field, newOrder);
          // Set the new sort settings
          this.sort = {
            name: field,
            order: newOrder
          }
        }
      }
    },
  },

  created() {
    this.getIndividualPool();
    this.getPoolMembers();
  },
}
</script>
