<template>
  <div class="pt-7">
    <div class="flex">
      <div class="flex flex-basis-15 flex-col p-5 mr-5 bg-white rounded-xl">
        <h1 class="text-xl font-bold">Sort</h1>

        <hr />

        <div class="flex justify-between items-center cursor-pointer select-none hover:text-olive" @click="sortProfiles('total_experience')">
          <h1 class="flex-1 font-bold my-2">Experience</h1>
          <div class="">
            <svg v-if="this.sort && this.sort.name == 'total_experience' && this.sort.order == 'desc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
            </svg>
            <svg v-if="this.sort && this.sort.name == 'total_experience' && this.sort.order == 'asc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
             <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
            </svg>
          </div>
        </div>
        <div class="flex justify-between items-center cursor-pointer select-none hover:text-olive" @click="sortProfiles('experience_company')">
          <h1 class="font-bold my-2">Company tenure</h1>
          <div>
            <svg v-if="this.sort && this.sort.name == 'experience_company' && this.sort.order == 'desc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
            </svg>
            <svg v-if="this.sort && this.sort.name == 'experience_company' && this.sort.order == 'asc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
             <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
            </svg>
          </div>
        </div>
        <div class="flex justify-between items-center cursor-pointer select-none hover:text-olive" @click="sortProfiles('loyalty')">
          <h1 class="font-bold my-2">Loyalty</h1>
          <div>
            <svg v-if="this.sort && this.sort.name == 'loyalty' && this.sort.order == 'desc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
            </svg>
            <svg v-if="this.sort && this.sort.name == 'loyalty' && this.sort.order == 'asc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
             <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
            </svg>
          </div>
        </div>
        <div class="flex justify-between items-center cursor-pointer select-none hover:text-olive" @click="sortProfiles('total_companies')">
          <h1 class="font-bold my-2">Companies</h1>
          <div>
            <svg v-if="this.sort && this.sort.name == 'total_companies' && this.sort.order == 'desc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
            </svg>
            <svg v-if="this.sort && this.sort.name == 'total_companies' && this.sort.order == 'asc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
             <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
            </svg>
          </div>
        </div>
        <div class="flex justify-between items-center cursor-pointer select-none hover:text-olive" @click="sortProfiles('salary')">
          <h1 class="font-bold my-2">Salary</h1>
          <div>
            <svg v-if="this.sort && this.sort.name == 'salary' && this.sort.order == 'desc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
            </svg>
            <svg v-if="this.sort && this.sort.name == 'salary' && this.sort.order == 'asc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
             <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
            </svg>
          </div>
        </div>
        <div class="flex justify-between items-center cursor-pointer select-none hover:text-olive" @click="sortProfiles('availability')">
          <h1 class="font-bold my-2">Availability</h1>
          <div>
            <svg v-if="this.sort && this.sort.name == 'availability' && this.sort.order == 'desc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7" />
            </svg>
            <svg v-if="this.sort && this.sort.name == 'availability' && this.sort.order == 'asc'" xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
             <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 15l7-7 7 7" />
            </svg>
          </div>
        </div>

        <div class="text-center mt-3 select-none">
          <span class="text-olive cursor-pointer" @click="sortProfiles('clear')">Clear filters</span>
        </div>
      </div>
      <div class="flex flex-grow flex-col">
        <BigCard v-for="profile in profiles" :profile="profile" :key="profile.hash" />
      </div>
    </div>
  </div>
</template>

<script>
// let apiEndpoint = 'http://localhost:9999'
let apiEndpoint = 'http://screened.remotehub.ro'

import BigCard from '@/components/BigCard'
import _ from 'lodash'

export default {
  name: 'Profiles',
  components: {
    BigCard
  },

  data() {
    return {
      profiles: [],
      sort: null
    };
  },

  methods: {
    async getAllProfiles() {
      try {
        let response = await fetch(apiEndpoint + "/api/profile.php?getAll");
        this.profiles = await response.json();
      } catch (error) {
        console.log(error);
      }
    },
    sortProfiles (field) {
      if (field == 'clear') {
        this.profiles = _.orderBy(this.profiles, 'parse_timestamp', 'desc');
        this.sort = null;
        return
      }

      // If current sort is null, we sort by this field in a descending order
      if (this.sort == null) {
        console.log(this.profiles)
        this.profiles = _.orderBy(this.profiles, field, 'desc');
  
        // Set the new sort settings
        this.sort = {
          name: field,
          order: 'desc'

        }
        console.log(this.profiles);
        console.log(this.sort)
      } else {
        // If the current sorted field is not the same sort by the new field in a descending order
        if (this.sort.name != field) {
          this.profiles = _.orderBy(this.profiles, field, 'desc');
          // Set the new sort settings
          this.sort = {
            name: field,
            order: 'desc'
          }
        } else {
          // Otherwise switch the type and order by the new settings
          let newOrder = this.sort.order == 'asc' ? 'desc' : 'asc'

          this.profiles = _.orderBy(this.profiles, field, newOrder);
          // Set the new sort settings
          this.sort = {
            name: field,
            order: newOrder
          }
        }
      }
    }
  },

  created() {
    this.getAllProfiles();
  },
}
</script>
