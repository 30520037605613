<template>
    <!-- <router-link :to="`/talent-pools/${this.talentpool.talent_pool_hash}`" class="flex flex-col bg-white rounded-xl mb-5 p-4 md:w-11/12 items-center content-center hover:shadow-2xl">
        <div class="rounded-full h-20 w-20 flex items-center justify-center mb-3 text-olive font-bold text-3xl border-2 border-olive justify-center">{{ this.talentpool.memberCount }}</div>
        <div class="text-olive font-bold border-b-2 border-olive">{{ this.talentpool.memberCount == 1 ? 'Profile' : 'Profiles' }}</div>
        <div class="pt-3 text-2xl text-olive font-bold">
            {{ this.talentpool.name }}
        </div>
        <div class="text-xl">
            {{ this.talentpool.location }}
        </div>
        <div class="text-sm pt-2">
            {{ this.talentpool.description }}
        </div>
    </router-link>
     -->

    <router-link :to="`/talent-pools/${this.talentpool.talent_pool_hash}`" class="flex flex-col bg-white rounded-xl mb-5 p-4  items-center content-center hover:shadow-2xl">
        <div class="rounded-full h-20 w-20 flex items-center justify-center mb-3 text-olive font-bold text-3xl border-2 border-olive justify-center">{{ this.talentpool.memberCount }}</div>
        <div class="text-olive font-bold border-b-2 border-olive">{{ this.talentpool.memberCount == 1 ? 'Profile' : 'Profiles' }}</div>
        <div class="pt-3 text-2xl text-olive font-bold">
            {{ this.talentpool.name }}
        </div>
        <div class="text-xl">
            {{ this.talentpool.location }}
        </div>
        <div class="text-sm pt-2">
            {{ this.talentpool.description }}
        </div>
    </router-link>
</template>

<script>
export default {
    name: 'TalentPoolCard',
    props: ['talentpool'],
}
</script>