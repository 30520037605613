<template>
  <div class="pt-7">


  <div>Dashboard page with info about latest parsed profiles, talent pools, graphics...etc</div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import BigCard from '@/components/BigCard'

export default {
  name: 'Home',
  components: {
    BigCard,
  }
}
</script>
