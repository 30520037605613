<template>
  <div class="flex flex-col pt-7">
    <div class="flex flex-col bg-white mb-5 rounded-xl p-4">
      <div class="text-olive pt-5 pb-2 text-xl font-bold">Create talent pool</div>
      <hr />

      <div v-if="error" class="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mt-5" role="alert">
        <strong class="font-bold">Error! </strong>
        <span v-if="!this.errorMessage" class="block sm:inline">Something went wrong when attempting to create the new talent pool, please try again.</span>
        <span v-if="this.errorMessage" class="block sm:inline">{{ this.errorMessage }}</span>
        <span class="absolute top-0 bottom-0 right-0 px-4 py-3">
          <svg class="fill-current h-6 w-6 text-red-500" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><title>Close</title><path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"/></svg>
        </span>
      </div>

      <div class="flex pt-5 space-x-5">
        <div class="flex-1 flex-col">
          <div class="font-bold py-1">Name</div>
          <div>
            <input v-model="this.poolCreateForm.name" class="block w-full rounded-md border-gray-300 focus:border-olive focus:bg-white focus:ring-0" type="text" />
          </div>
        </div>
        <div class="flex-1 flex-col">
          <div class="font-bold py-1">Location</div>
          <div>
            <input v-model="this.poolCreateForm.location" class="block w-full rounded-md border-gray-300 focus:border-olive focus:bg-white focus:ring-0" type="text" />
          </div>
        </div>
      </div>
      <div class="flex flex-col mt-2">
        <div class="font-bold py-1">Description</div>
        <div>
          <textarea v-model="this.poolCreateForm.description" class="block w-full rounded-md border-gray-300 focus:border-olive focus:bg-white focus:ring-0" rows="3"></textarea>
        </div>
      </div>
      <div class="flex mt-5 justify-end">
        <button class="bg-olive px-5 py-2 rounded-md text-white font-bold hover:shadow-md" @click="createPool">Create talent pool</button>
      </div>
    </div>
    <TalentPoolCard v-for="talentpool in talentpools" :talentpool="talentpool" :key="talentpool.talent_pool_hash" />
  </div>
</template>

<script>
// let apiEndpoint = 'http://localhost:9999'
let apiEndpoint = 'http://screened.remotehub.ro'

import TalentPoolCard from '@/components/TalentPoolCard.vue'

export default {
  name: 'TalentPools',
  components: {
    TalentPoolCard
  },
  data() {
    return {
      talentpools: [],
      poolCreateForm: {
        name: "",
        location: "",
        description: ""
      },
      error: null,
      errorMessage: null,
    };
  },

  methods: {
    async getAllPools() {
      try {
        
        let response = await fetch(apiEndpoint + "/api/talentpool.php?getAll");
        this.talentpools = await response.json();
      } catch (error) {
        console.log(error);
      }
    },
    createPool() {
      this.resetError()
      try {
        fetch(apiEndpoint + "/api/talentpool.php?createPool", {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json' 
          },
          body: JSON.stringify(this.poolCreateForm),
          })
          .then(response => response.json())
          .then(data => {
            
            // this.saveButtonText = 'Save changes'
            if (data.status == 'SUCCESS') {
              this.getAllPools();
              this.resetCreateForm();
              this.resetError()
             } else {
               if (data.status != 'ERROR') {
                 this.errorMessage = data.status
               }

               this.error = true;
             }
          })
        } catch (error) {
          console.log(error);
          this.error = true;
          // this.saveButtonText = 'Save changes'
      }
    },
    resetCreateForm() {
      this.poolCreateForm.name = "";
      this.poolCreateForm.location = "";
      this.poolCreateForm.description = "";
    },
    resetError() {
      this.error = false
      this.errorMessage = false
    }
  },

  created() {
    this.getAllPools();
  },
}
</script>
