import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'

// createApp(App).use(router).mount('#app')
const app = createApp(App)
app.use(router)
// Custom filters
app.config.globalProperties.$filters = {
    initials(value) {
        if (value == undefined) {
            return 'N/A'
        }

        let rgx = new RegExp(/(\p{L}{1})\p{L}+/, 'gu')
        let initials = [...value.matchAll(rgx)] || []
        initials = (
            (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
            ).toUpperCase()

        return initials
    },
    screenedDate(m) {
        // If not currently employed return N/A
        if (m == null) {
            return 'N/A'
        } else {
            if (m < 12) {
                return m + ' months'
            } else {
                let years = Math.floor(m/12);
                let months = m % 12;

                if (months == 0) {
                    return years == 1 ? years + ' year' : years + ' years'
                } else {
                    let yrs_str = years == 1 ? years + ' year' : years + ' years'
                    let mnt_str = months == 1 ? months + ' month' : months + ' months'

                    return yrs_str + ' ' + mnt_str
                }
                // // Split months in years
                // let my = m / 12
                // // return 'X years ' + m + 'months'
                // return ((my % 1 !== 0) ? my.toFixed(1) : my) + ' years'
            }
        }
    },
    screenedSalary(s) {
        if (s == null || s == 0) {
            return 'Not defined'
        }

        return '$' + s.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    screenedAvailability(a) {
        if (a == null) {
            return 'Not defined'
        }

        if (a == 0.2) {
            return '1 week'
        } else if (a == 0.5) {
            return '2 weeks'
        } else if (a == 1) {
            return '1 month'
        } else {
            return a + 'months'
        }
    }
}



app.mount('#app')