<template>
  <div>
    <Header />
    <Breadcrumbs :childBreadcrumb="childBreadcrumb"/>
    <div class="bg-gray-100 h-full min-h-screen">
      <div class="container mx-auto">
        <router-view @setChildBreadcrumb="setChildBreadcrumb" />
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header'
import Breadcrumbs from '@/components/Breadcrumbs'

export default ({
  components: {
    Header,
    Breadcrumbs
  },
  data() {
    return {
      childBreadcrumb: ''
    }
  },
  methods: {
    setChildBreadcrumb(breadcrumb) {
      this.childBreadcrumb = breadcrumb;
    }
  }
})
</script>
