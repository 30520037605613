import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Profiles from '../views/Profiles.vue'
import Profile from '../views/Profile.vue'
import TalentPools from '../views/TalentPools.vue'
import TalentPool from '../views/TalentPool.vue'

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Home
  },
  {
    path: '/profiles',
    name: 'Profiles',
    component: Profiles
  },
  {
    path: '/profiles/:profileHash',
    name: 'Individual Profile',
    component: Profile
  },
  {
    path: '/talent-pools',
    name: 'Talent Pools',
    component: TalentPools
  },
  {
    path: '/talent-pools/:poolHash',
    name: 'Individual Pool',
    component: TalentPool
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
