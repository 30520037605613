<template>
    <div class="bg-white container mx-auto py-3 flex justify-between ">
        <!-- <TalentPoolCreationModal ref="creationModal"></TalentPoolCreationModal> -->
        <div class="flex items-center">
            <div v-if="this.$route.name === 'Talent Pools' || this.$route.name === 'Individual Pool'" class="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-9 w-9" fill="none" viewBox="0 0 24 24" stroke="#acbf69">>
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01" />
                </svg>

                <router-link to="/talent-pools">
                    <h1 class="text-2xl font-bold text-olive ml-2">Talent Pools</h1>
                </router-link>

                <div v-if="this.$route.name === 'Individual Pool'" class="flex">
                    <span class="font-bold text-olivelight ml-2 text-2xl">&raquo;</span>
                    <h1 class="font-bold text-olive ml-2 text-2xl">{{this.childBreadcrumb }}</h1>
                </div>

                <!-- <div v-if="this.$route.name === 'Individual Pool'" class="flex">
                    <span class="font-bold text-olivelight ml-2 text-2xl">&raquo;</span>
                    <h1 class="font-bold text-olive ml-2 text-2xl">{{ this.$route.params.poolHash }}</h1>
                </div> -->
            </div>

            <div v-else-if="this.$route.name === 'Profiles' || this.$route.name === 'Individual Profile'" class="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-9 w-9" fill="none" viewBox="0 0 24 24" stroke="#acbf69">>
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>

                <router-link to="/profiles">
                    <h1 class="text-2xl font-bold text-olive ml-2">Profiles</h1>
                </router-link>

                <div v-if="this.$route.name === 'Individual Profile'" class="flex">
                    <span class="font-bold text-olivelight ml-2 text-2xl">&raquo;</span>
                    <h1 class="font-bold text-olive ml-2 text-2xl">{{ this.childBreadcrumb }}</h1>
                </div>
            </div>

            <div v-else-if="this.$route.name === 'Dashboard'" class="flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-9 w-9" fill="none" viewBox="0 0 24 24" stroke="#acbf69">>
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                </svg>

                <router-link to="/">
                    <h1 class="text-2xl font-bold text-olive ml-2">{{ this.$route.name }}</h1>
                </router-link>
            </div>
        </div>

        <!-- Right actions -->

        <div @click="openCreationModal()" v-if="this.$route.name === 'Talent Pools'" class="flex items-center flex-col text-olive cursor-pointer">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-9 w-9" fill="none" viewBox="0 0 24 24" stroke="#acbf69">>
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span class="ml-1 mr-1 font-bold">Create talent pool</span>
        </div>

        <div v-else class="flex items-center flex-col text-white">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-9 w-9" fill="none" viewBox="0 0 24 24" stroke="white">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v3m0 0v3m0-3h3m-3 0H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span class="ml-1 mr-1 font-bold">&nbsp;</span>
        </div>
    </div>

</template>

<script>
// import TalentPoolCreationModal from '@/components/TalentPoolCreationModal.vue'

export default {
    props: ['childBreadcrumb'],
    // components: {
    //     TalentPoolCreationModal
    // },
    methods: {
        // openCreationModal() {
        //     this.$refs.creationModal.openModal();
        // }
    },
}
</script>

<style>
.ownOlive {
    color: #acbf69;
}
</style>